export enum FormValidationError {
    REQUIRED = 'REQUIRED',
    EXCEEDS_MAX_LENGTH = 'EXCEEDS_MAX_LENGTH',
    EXCEEDS_MAX_VALUE = 'EXCEEDS_MAX_VALUE',
    EXCEEDS_MAX_CHOICES = 'EXCEEDS_MAX_CHOICES',
    INSUFFICIENT_LENGTH = 'INSUFFICIENT_LENGTH',
    INSUFFICIENT_VALUE = 'INSUFFICIENT_VALUE',
    INSUFFICIENT_CHOICES = 'INSUFFICIENT_CHOICES',
    REGEX_PATTERN = 'REGEX_PATTERN'
}
